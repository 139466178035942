































































import { computed, defineComponent, ref } from '~/utils/nuxt3-migration'
import HeaderUserMenu from '~/components/car/header/HeaderUserMenu.vue'
import { formatNumber } from '~/utils/number'
import { useNamespacedStore } from '~/compositions/store'
import { USER_NS, UserState } from '~/store/modules/shared/user/state'
import { useUserAgent } from '~/compositions/user-agent'
import { ciUserCircle as ciUserCircleLight } from '~/icons/source/light/user-circle'
import { ciUserCircle as ciUserCircleSolid } from '~/icons/source/solid/user-circle'

export default defineComponent({
  components: {
    HeaderUserMenu
  },
  setup() {
    const { state, getters } = useNamespacedStore<UserState>(USER_NS)
    const { isPc } = useUserAgent()

    const shown = ref(false)
    const isModalVisible = ref(false)

    const avatar = computed(() => state.avatar)
    const username = computed(() => state.username)
    const isAnon = computed(() => getters('isAnon'))
    const isSingleOrAnon = computed(() => getters('isSingleOrAnon'))
    const totalCredits = computed(() => getters('totalCredits'))
    const showNotificationsIndicator = computed(() => {
      const unreadCounts = [
        state.unreadMessages,
        state.unreadNotifications,
        state.unreadParkingNotifications,
        state.unreadTradesNotifications,
        state.unreadAnnouncements,
        state.rogueClassifiedsCount,
        getters('marketplaceSellerNewOrdersCount')
      ]
      return unreadCounts.some(count => count > 0)
    })
    const totalCreditsFormatted = computed(() =>
      formatNumber(totalCredits.value)
    )

    function enter() {
      if (!isPc.value) return
      shown.value = true
    }
    function leave() {
      shown.value = false
    }

    function handleClickUser(e: Event) {
      if (isSingleOrAnon.value && !isPc.value) {
        e.preventDefault()
        isModalVisible.value = true
      }
    }

    return {
      ciUserCircleLight,
      ciUserCircleSolid,
      isAnon,
      avatar,
      username,
      showNotificationsIndicator,
      totalCredits,
      totalCreditsFormatted,
      isSingleOrAnon,
      shown,
      enter,
      leave,
      handleClickUser,
      isModalVisible
    }
  }
})
